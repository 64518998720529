import React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useState } from 'react';
import { useCategories } from '@elevio/kb-kit/lib/hooks';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Articles from '@elevio/kb-kit/lib/components/articles';

function CategoryList({ className }) {
  const { categories, totalCount } = useCategories();

  let [isExpanded, setIsExpanded] = useState(false);
  let [targetName, setTargetName] = useState('');

  const toggle = (e) => {
    setIsExpanded(!isExpanded);
    setTargetName(e.target.firstChild.data.trim());
  };

  if (totalCount === 0) return null;

  return (
    <div className={className}>
      <h3 id="categories-text">
        <Trans i18nKey="category.listTitle">Categories</Trans>
      </h3>
      {categories.map((category) => {
        return (
          <ul className="category-list-ul">
            <li>
              <div>
                <button
                  className="category-list-button"
                  onClick={(e) => toggle(e)}
                >
                  <svg
                    className={
                      isExpanded && category.title.trim() === targetName
                        ? 'plus minus'
                        : 'plus'
                    }
                    width="12"
                    height="12"
                    viewBox="0 0 160 160"
                  >
                    <rect
                      className="vertical-line"
                      x="65"
                      width="30"
                      height="160"
                    />
                    <rect
                      className="horizontal-line"
                      y="65"
                      width="160"
                      height="30"
                    />
                  </svg>
                  <h4 className="category-list-button-text">{category.title}</h4>
                </button>
              </div>
              {isExpanded && category.title.trim() === targetName && (
                <Articles.Loop categoryId={category.id}>
                    <Article.Link className="article-header">
                      <Article.Title />
                    </Article.Link>               
                </Articles.Loop>
              )}
            </li>
          </ul>
        );
      })}
    </div>
  );
}
export default CategoryList;