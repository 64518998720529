import React from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useArticleFull } from '@elevio/kb-kit/lib/hooks';
import AccessBanner from './AccessBanner';

function ArticleMeta({ className }) {
  const article = useArticleFull();
  if (!article) return null;
  return (
    <>
      <AccessBanner />
    </>
  );
}

export default ArticleMeta;
