import React from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useArticles, useCategory } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Meta from '@elevio/kb-kit/lib/components/meta';

function CategoryCard({ categoryId, className }) {
  const category = useCategory({
    categoryId,

  });
  const { totalCount } = useArticles({
    categoryId,
  });
  if (!category) return null;
  const subCategoriesCount = (category.subCategories || []).length;

  const catvalue = category.id;
  const imgSrc = category.id == 14 ? 'https://app.sharesquare.co/images/VirtualStockUnits.png' :
    category.id == 24 ? 'https://app.sharesquare.co/images/noun_price_427280.png' :
      category.id == 19 ? 'https://app.sharesquare.co/images/noun_growth_429613.png' :
        category.id == 49 ? 'https://app.sharesquare.co/images/Ssq5.png' :
          'https://app.sharesquare.co/images/SAR-icoon-2.png'
 
          return (
    <Category.Link
      className={cx('card', className)}
      data-testid="category-link"
    >

      <img src={imgSrc} alt="Sharesquare" className="card-icon"></img>
      <h2 className="card-title">{category.title}</h2>
      <div className="card-divider" />
      <p className="card-info">
        <Meta.If test={subCategoriesCount > 0}>
          <Trans
            i18nKey="categorycard.categoriesCount"
            count={subCategoriesCount}
          >
            {{
              count: subCategoriesCount,
            }}{' '}
            Categories
          </Trans>
        </Meta.If>
        <Meta.If
          test={subCategoriesCount > 0 && !!totalCount && totalCount > 0}
        >
          {' '}
          &amp;{' '}
        </Meta.If>
        <Meta.If test={!!totalCount && totalCount > 0}>
          <Trans i18nKey="categorycard.articlesCount" count={totalCount || 0}>
            {{
              count: totalCount,
            }}{' '}
            Articles
          </Trans>
        </Meta.If>
      </p>
    </Category.Link>

  );
}
export default CategoryCard;
