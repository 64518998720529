import React from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import LoginLogout from './LoginLogout';

function Footer({ className }) {
  return (

<footer className={cx('footer', className)} data-testid="footer">
		<div id="footer-info">
       <a id="terms" href="https://sharesquare.co/wp-content/themes/sharesquare/assets/pdf/terms-and-conditions.pdf" target="_blank">Terms and Conditions</a>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;    
       <a  id="terms" href="https://sharesquare.co/wp-content/themes/sharesquare/assets/pdf/privacy-policy.pdf" target="_blank">Privacy Policy</a>
         
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;  &nbsp;&nbsp;   
        <a  id="terms" href="https://knowledge.sharesquare.co/en/articles/73" target="_blank">Knowledge Base Disclaimer</a>
        <p>© The Co-ownership Company B.V. 2021. All rights reserved.</p>

 </div>
</footer>
  );
}

export default Footer;
