import React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';

function Loader() {
  return (
    <div className="page-loader">
      <Trans i18nKey="loading"> 
      The article you have requested is restricted to logged in users. 
      If you have an account, <a href="https://app.sharesquare.co/login"> Login Here</a>
      </Trans>
    </div>
  );
}

export default Loader;
